let routeName = 'module->App->Module';

export const module = {
  info: {
    fullName: 'App-Module',
    namespace: 'App',
    name: 'Module',
    caption: 'Module',
    routeName: 'module->App->Module',
    viewGroup: 'system'
  },
  vuexModules: ['api', 'state'],
  mainRoute: '/cpanel',
  // noRoutes: true,
  router: {
    mainRouteName: 'portal-cpanel',
    routes:
        {
          path: 'Module',
          name: routeName,
          component: () => import('./View.vue'),
          children: [
            {
              path: 'create',
              name: routeName + '->create',
              component: () => import('./create/View'),
            },
            {
              path: 'list',
              name: routeName + '->list',
              component: () => import('./list/View'),
              children: [
                {
                  path: ':listPage',
                  name: routeName + '->list->page',
                  component: () => import('./list/page/View'),
                }
              ]
            },
            {
              path: ':entityTitle',
              name: routeName + '->view',
              component: () => import('./view/View.vue'),
            }
          ]
        }
  },
  sideMenu: {
    topSeparator: false,
    caption: 'Module',
    icon: 'el-icon-folder',
    routeName: 'list->page',
    routeParams: { listPage: 1}
    /*children: [
      {
        caption: 'Create Entity',
        icon: 'el-icon-plus',
        routeName: 'create'
      },
      {
        caption: 'List Entities',
        icon: 'el-icon-more-outline',
        routeName: 'list->page',
        routeParams: { listPage: 1}
      }
    ]*/
  }
}

export default module
